  <script lang="ts">
  export default {
      name:"ModalBase",
      props : {
          activatorId : { type : String, required : false, default : "" },
          maxWidth : { type : Number, required : false, default : null },
          defaultUrl : { type : String, required : false, default : "" },
          titleModal : { type : String, required : true },
          permissionName : { type : String, required : false, default : "" },
          translationName : { type : String, required : false, default : "" },
          data : { type : Object, required : false, default : () => ({}) },
          isForm : { type : Boolean },
          saving : { type : Boolean },
          hideDefaultActions: { type : Boolean, required : false },
          hideTopButtons: { type : Boolean, required : false },
          editDisabled : { type : Boolean, required : false },
          deleteDisabled : { type : Boolean, required : false },
          saveText : { type : String, required : false, default : "Sauvegarder" },
          customButtonColor : { type : String, required : false, default : "secondary" },
          customButtonBlock : { type : Boolean, required : false },
          disableSaveButton : { type : Boolean, required : false },
      },
      data () {
          return {
              drawer: false,
              showCloseMessage : false,
          }
      },
      computed : {
          currentKey () {
              return this.$route.params.k
          },
      },
      methods : {
            scrollToTop () {
                // scrollToTop (ref:string) {
                // const el = ref ? this.$refs.content : this.$refs[ref];
                const el = this.$refs.content;
                if (el) {
                    el.scrollIntoView({behavior: 'smooth'});
                }
            },
          showModal () {
            //   this.drawer = !this.drawer
                this.drawer = true
          },
          closeModal (showMessage:boolean = false) {
            if (showMessage && this.isForm) {
              this.showCloseMessage = true
              this.drawer = true
            } else {
              this.$emit('closeModal');
              this.drawer = false
            //   this.showModal()
            }
          },
          redirectEdit (path:string | null = null) {
              const currentKey = this.currentKey
              this.closeModal()
              setTimeout( () => {
                  this.$router.replace(path ? path : `${this.defaultUrl}/${currentKey}/formulaire`)
              }, 100); 
          },
          redirectTable () {
              this.closeModal()
              setTimeout( () => {
                  this.$router.replace(`${this.defaultUrl}`)
              }, 100); 
          },
      },
  }
  </script>
  